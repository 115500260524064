import React from 'react'
import demo_img1 from './../../img/home-img/banner-02.jpg'

export default function Advertisementthird() {
  return (
    <div class="col-md-12 ptop--30 pbottom--30">
        <div class="ad--space">
            <a href="#">
              <img src={demo_img1} alt="" style={{width: "728px", height:"90px"}} class="center-block"/>
            </a>
        </div>
    </div>
  )
}
