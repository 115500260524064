import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Entertainment() {
  return (
    <div class="col-md-6 ptop--30 pbottom--30">
        <div class="post--items-title" data-ajax="tab">
            <h2 class="h4">Entertainment</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_sports_posts">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_sports_posts">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>
        
        <div class="post--items post--items-3" data-ajax-content="outer">
            <ul class="nav" data-ajax-content="inner">
                <li>
                    <div class="post--item post--layout-1">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "450px", height:"219px"}}/></a>
                            <a href="#" class="cat">Basketball</a>
                            <a href="#" class="icon"><i class="fa fa-eye"></i></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Bathin</a></li>
                                    <li><a href="#">Yeasterday 03:52 pm</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be distracted by</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="post--item post--layout-3">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Bune</a></li>
                                    <li><a href="#">16 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="post--item post--layout-3">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Bune</a></li>
                                    <li><a href="#">16 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="post--item post--layout-3">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Bune</a></li>
                                    <li><a href="#">16 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="post--item post--layout-3">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Bune</a></li>
                                    <li><a href="#">16 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="preloader bg--color-0--b" data-preloader="1">
                <div class="preloader--inner"></div>
            </div>
        </div>
    </div>
  )
}
