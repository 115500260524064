import React from 'react'
import Advertisementfourth from '../Advertisement/Advertisementfourth'
import Testimonial from '../Common/Testimonial'
import Audiovideo from '../Newsbox/Audiovideo'
import Crime from '../Newsbox/Crime'
import Miscellaneous from '../Newsbox/Miscellaneous'
import Photogallery from '../Newsbox/Photogallery'
import Religion from '../Newsbox/Religion'
import Sarcasm from '../Newsbox/Sarcasm'
import Pollingcheckbox from '../polling/Pollingcheckbox'
import Pollingradio from '../polling/Pollingradio'

export default function Thirdsection() {
  return (
    <>
        <Audiovideo />
        <div class="row">
            <div class="main--content col-md-8 col-sm-7" data-sticky-content="true">
                <div class="sticky-content-inner">
                    <div class="row">
                        <Religion />
                        
                        <Sarcasm />
                        
                        <Miscellaneous />
                        
                        <Photogallery />
                    </div>
                </div>
            </div>
            
            <div class="main--sidebar col-md-4 col-sm-5 ptop--30 pbottom--30" data-sticky-content="true">
                <div class="sticky-content-inner">
                    <Pollingcheckbox />
                    <Pollingradio />

                    <Advertisementfourth />
                    
                    <Testimonial />
                    
                    <Crime />
                </div>
            </div>
        </div>
    </>
    
  )
}
