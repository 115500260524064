import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Advertisementfourth() {
  return (
    <div class="widget">
        <div class="ad--widget">
            <div class="row">
                <div class="col-sm-6">
                    <a href="#">
                        <img src={demo_img1} alt="" style={{width: "150px", height:"150px"}} />
                    </a>
                </div>

                <div class="col-sm-6">
                    <a href="#">
                        <img src={demo_img1} alt="" style={{width: "150px", height:"150px"}} />
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}
