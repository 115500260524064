import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Sport() {
  return (
    <div class="col-md-6 ptop--30 pbottom--30">
        <div class="post--items-title" data-ajax="tab">
            <h2 class="h4">Sports</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_politics_posts">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_politics_posts">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>
        
        <div class="post--items post--items-2" data-ajax-content="outer">
            <ul class="nav row gutter--15" data-ajax-content="inner">
                <li class="col-xs-12">
                    <div class="post--item post--layout-1">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "450px", height:"219px"}}/></a>
                            <a href="#" class="cat">Election</a>
                            <a href="#" class="icon"><i class="fa fa-fire"></i></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">Yeasterday 03:52 pm</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be distracted by</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-xs-12">
                    <hr class="divider" />
                </li>
                <li class="col-xs-6">
                    <div class="post--item post--layout-2">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "180px", height:"100px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Hantu Raya</a></li>
                                    <li><a href="#">17 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will done</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-xs-6">
                    <div class="post--item post--layout-2">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "180px", height:"100px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">17 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will done</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-xs-12">
                    <hr class="divider" />
                </li>
                <li class="col-xs-6">
                    <div class="post--item post--layout-2">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "180px", height:"100px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">17 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will done</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-xs-6">
                    <div class="post--item post--layout-2">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "180px", height:"100px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Hantu Raya</a></li>
                                    <li><a href="#">17 April 2017</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will done</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="preloader bg--color-0--b" data-preloader="1">
                <div class="preloader--inner"></div>
            </div>
        </div>
    </div>
  )
}
