import React from 'react'

export default function Newsflash() {
  return (
    <div className="news--ticker">
            <div className="container">
                <div className="title">
                    <h2>News Updates</h2>
                    <span>(Update 12 minutes ago)</span>
                </div>

                <div className="news-updates--list" data-marquee="true">
                    <ul className="nav">
                        <li>
                            <h3 className="h3"><a href="#">Contrary to popular belief Lorem Ipsum is not simply random text.</a></h3>
                        </li>
                        <li>
                            <h3 className="h3"><a href="#">Education to popular belief Lorem Ipsum is not simply</a></h3>
                        </li>
                        <li>
                            <h3 className="h3"><a href="#">Lorem ipsum dolor sit amet consectetur adipisicing elit.</a></h3>
                        </li>
                        <li>
                            <h3 className="h3"><a href="#">Corporis repellendus perspiciatis reprehenderit.</a></h3>
                        </li>
                        <li>
                            <h3 className="h3"><a href="#">Deleniti consequatur laudantium sit aspernatur?</a></h3>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
  )
}
