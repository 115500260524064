import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Economy() {
  return (
    <div class="col-md-12 ptop--30 pbottom--30">
        <div class="post--items-title" data-ajax="tab">
            <h2 class="h4">Finance</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_finance_posts">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_finance_posts">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>
        
        <div class="post--items post--items-2" data-ajax-content="outer">
            <ul class="nav row" data-ajax-content="inner">
                <li class="col-md-6">
                    <div class="post--item post--layout-2">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "360px", height:"243px"}}/></a>
                            <a href="#" class="cat">Business</a>
                            <a href="#" class="icon"><i class="fa fa-star-o"></i></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Vassago</a></li>
                                    <li><a href="#">Today 03:30 am</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas.</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="col-md-6">
                    <ul class="nav row">
                        <li class="col-xs-12 hidden-md hidden-lg">
                            <hr class="divider" />
                        </li>

                        <li class="col-xs-6">
                            <div class="post--item post--layout-2">
                                <div class="post--img">
                                    <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "165px", height:"97px"}}/></a>

                                    <div class="post--info">
                                        <ul class="nav meta">
                                            <li><a href="#">Zepar</a></li>
                                            <li><a href="#">Today 03:52 pm</a></li>
                                        </ul>

                                        <div class="title">
                                            <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="col-xs-6">
                            <div class="post--item post--layout-2">
                                <div class="post--img">
                                    <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "165px", height:"97px"}}/></a>

                                    <div class="post--info">
                                        <ul class="nav meta">
                                            <li><a href="#">Demiurge</a></li>
                                            <li><a href="#">Today 03:02 pm</a></li>
                                        </ul>

                                        <div class="title">
                                            <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="col-xs-12">
                            <hr class="divider" />
                        </li>

                        <li class="col-xs-6">
                            <div class="post--item post--layout-2">
                                <div class="post--img">
                                    <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "165px", height:"97px"}}/></a>

                                    <div class="post--info">
                                        <ul class="nav meta">
                                            <li><a href="#">Demiurge</a></li>
                                            <li><a href="#">Today 02:05 am</a></li>
                                        </ul>

                                        <div class="title">
                                            <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="col-xs-6">
                            <div class="post--item post--layout-2">
                                <div class="post--img">
                                    <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "165px", height:"97px"}}/></a>

                                    <div class="post--info">
                                        <ul class="nav meta">
                                            <li><a href="#">Zepar</a></li>
                                            <li><a href="#">Today 12:35 am</a></li>
                                        </ul>

                                        <div class="title">
                                            <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>

            <div class="preloader bg--color-0--b" data-preloader="1">
                <div class="preloader--inner"></div>
            </div>
        </div>
    </div>
  )
}
