import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Newsflash from './Common/Newsflash'
import Topfilter from './Common/Topfilter'
import Firstsection from './Home/Firstsection'
import Secondsection from './Home/Secondsection'
import Thirdsection from './Home/Thirdsection'

export default function Homepage() {
  return (
    <>
        {/* <div id="preloader">
            <div className="preloader bg--color-1--b" data-preloader="1">
                <div className="preloader--inner"></div>
            </div>
        </div> */}
        <div className="wrapper">
            <Header />
            <Topfilter />
            <Newsflash />
            <div className="main-content--section pbottom--30">
              <div className="container">
                <Firstsection />
                <div class="row">
                  <Secondsection />
                </div>
                <Thirdsection />
              </div>
            </div>
            <Footer />
        </div>
    </>
  )
}
