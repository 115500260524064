import React from 'react'

export default function Footer() {
  return (
    <>
        <footer className="footer--section">
            
            <div className="footer--widgets pd--30-0 bg--color-2">
                <div className="container">
                    <div className="row AdjustRow">
                        <div className="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
                            
                            <div className="widget">
                                <div className="widget--title">
                                    <h2 className="h4">About Us</h2>

                                    <i className="icon fa fa-exclamation"></i>
                                </div>

                                
                                <div className="about--widget">
                                    <div className="content">
                                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium laborum et dolorum fuga.</p>
                                    </div>

                                    <div className="action">
                                        <a href="#" className="btn-link">Read More<i className="fa flm fa-angle-double-right"></i></a>
                                    </div>

                                    <ul className="nav">
                                        <li>
                                            <i className="fa fa-map"></i>
                                            <span>143/C, Fake Street, New Delhi, India</span>
                                        </li>
                                        <li>
                                            <i className="fa fa-envelope-o"></i>
                                            <a href="mailto:example@example.com">example@example.com</a>
                                        </li>
                                        <li>
                                            <i className="fa fa-phone"></i>
                                            <a href="tel:+123456789">+123 456 (789)</a>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                            
                        </div>

                        <div className="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
                            
                            <div className="widget">
                                <div className="widget--title">
                                    <h2 className="h4">Usefull Info Links</h2>

                                    <i className="icon fa fa-expand"></i>
                                </div>

                                <div className="links--widget">
                                    <ul className="nav">
                                        <li><a href="#" className="fa-angle-right">Gadgets</a></li>
                                        <li><a href="#" className="fa-angle-right">Shop</a></li>
                                        <li><a href="#" className="fa-angle-right">Term and Conditions</a></li>
                                        <li><a href="#" className="fa-angle-right">Forums</a></li>
                                        <li><a href="#" className="fa-angle-right">Top News of This Week</a></li>
                                        <li><a href="#" className="fa-angle-right">Special Recipes</a></li>
                                        <li><a href="#" className="fa-angle-right">Sign Up</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
                            
                            <div className="widget">
                                <div className="widget--title">
                                    <h2 className="h4">Advertisements</h2>

                                    <i className="icon fa fa-bullhorn"></i>
                                </div>
                                <div className="links--widget">
                                    <ul className="nav">
                                        <li><a href="#" className="fa-angle-right">Post an Add</a></li>
                                        <li><a href="#" className="fa-angle-right">Adds Renew</a></li>
                                        <li><a href="#" className="fa-angle-right">Price of Advertisements</a></li>
                                        <li><a href="#" className="fa-angle-right">Adds Closed</a></li>
                                        <li><a href="#" className="fa-angle-right">Monthly or Yearly</a></li>
                                        <li><a href="#" className="fa-angle-right">Trial Adds</a></li>
                                        <li><a href="#" className="fa-angle-right">Add Making</a></li>
                                    </ul>
                                </div>
                                
                            </div>
                            
                        </div>

                        <div className="col-md-3 col-xs-6 col-xxs-12 ptop--30 pbottom--30">
                            
                            <div className="widget">
                                <div className="widget--title">
                                    <h2 className="h4">Career</h2>

                                    <i className="icon fa fa-user-o"></i>
                                </div>

                                <div className="links--widget">
                                    <ul className="nav">
                                        <li><a href="#" className="fa-angle-right">Available Post</a></li>
                                        <li><a href="#" className="fa-angle-right">Career Details</a></li>
                                        <li><a href="#" className="fa-angle-right">How to Apply?</a></li>
                                        <li><a href="#" className="fa-angle-right">Freelence Job</a></li>
                                        <li><a href="#" className="fa-angle-right">Be a Member</a></li>
                                        <li><a href="#" className="fa-angle-right">Apply Now</a></li>
                                        <li><a href="#" className="fa-angle-right">Send Your Resume</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer--copyright bg--color-3">
                <div className="social--bg bg--color-1"></div>

                <div className="container">
                    <p className="text float--left" style={{color: "#fff"}}>&copy; 2023 <a href="#">NEWS</a>. All Rights Reserved.</p>

                    <ul className="nav social float--right">
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                        <li><a href="#"><i className="fa fa-youtube-play"></i></a></li>
                    </ul>

                    <ul className="nav links float--right">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">FAQ</a></li>
                        <li><a href="#">Support</a></li>
                    </ul>
                </div>
            </div>
        </footer>
        <div id="backToTop">
            <a href="#"><i className="fa fa-angle-double-up"></i></a>
        </div>
    </>
  )
}
