import React, { Component } from 'react'
import NewsItem from './NewsItem'
import Spinner from './Spinner';
import PropTypes from 'prop-types'
import InfiniteScroll from "react-infinite-scroll-component";


export default class News extends Component {
    static defaultProps = {
        country:'in',
        pageSize:4,
        category:'general',
    }
    static propTypes = {
        country: PropTypes.string,
        pageSize:PropTypes.number
    }
    articles = []

    apikey = process.env.REACT_APP_NEWS_KEY

    constructor(props){
        super(props);
        this.state = {
            articles: [],
            loading:false,
            page:1,
            totalResults:0
        }
        document.title = `${this.captialize(this.props.category)}`;
    }

    async componentDidMount(){
        if (this.first) return; this.first = true;
        this.updateNews();
    }

    captialize = (word)=>{
        let text = word.toLowerCase();
        return text[0].toUpperCase() + text.slice(1);
    }

    

    fetchMoreData = () => {
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        this.getNewsData(this.state.page + 1); 
    };

    updateNews = async ()=>{
        console.log(this.props.setProgress(10))
        
        
        this.setState({
            
            loading:true
        })
        
        let url = `https://newsapi.org/v2/top-headlines?country=${this.props.country}&apiKey=${this.apikey}&pageSize=${this.props.pageSize}&page=${this.state.page}&category=${this.props.category}`;
        let data = await fetch(url);
        let parseData = await data.json();
        console.log(parseData);

        this.setState({
            articles:this.state.articles.concat(parseData.articles),
            totalResults:parseData.totalResults,
            loading:false
        }) 
        
         
        this.props.setProgress(100);
        
    }

    getNewsData = async (page)=>{
        
        this.setState({
            loading:true,
            page:page + 1,
        }) 

        if(page > Math.ceil(this.state.totalResults/this.props.pageSize) && page !== 1) {

        } else {
            let url = `https://newsapi.org/v2/top-headlines?country=${this.props.country}&apiKey=514a1356c9c743119de97999235c17f1&pageSize=${this.props.pageSize}&page=${page}&category=${this.props.category}`;
            let data = await fetch(url);
            let parseData = await data.json();
            console.log(parseData);

            this.setState({
                articles:this.state.articles.concat(parseData.articles),
                totalResults:parseData.totalResults
            }) 
        }
        this.setState({
            loading:false
        }) 
        
        
    }

  render() {
    return (
      <div>
        <div className='text-center mt-5'><h2>Top Headlines From - {this.captialize(this.props.category)}</h2></div>
            {/*this.state.loading && <Spinner />*/}
            
            <InfiniteScroll
                dataLength={this.state.articles.length}
                next={this.fetchMoreData}
                hasMore={this.state.articles.length !== this.state.totalResults}
                loader={<Spinner />}
            >
                <div className="container mt-5">
                    <div className='row' >
                        {this.state.articles.map((element)=>{
                            return <div className='col-md-3' key={element.url}><NewsItem  title={element.title?element.title.slice(0,20):""} description={element.description ? element.description.slice(0,40):""} imageUrl={element.urlToImage} newsUrl = {element.url} publishBy={element.author?element.author:"Unknown"} publishAt={element.publishedAt} sourceName = {element.source.name}/></div>
                        })}
                    </div>
                </div>
            </InfiniteScroll>
            
            
            
        </div>
    )
  }
}
