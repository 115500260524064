import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Advertisementfirst() {
  return (
    <div class="widget">
        <div class="ad--widget">
            <a href="#">
            <img src={demo_img1} alt="" style={{width: "300px", height:"250px"}} />
            </a>
        </div>
    </div>
  )
}
