import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'
import demo_img2 from './../../img/home-img/banner-02.jpg'

export default function Audiovideo() {
  return (
    <>
            <div class="main--content pd--30-0">
                <div class="post--items-title" data-ajax="tab">
                    <h2 class="h4">Audio &amp; Videos</h2>

                    <div class="nav">
                        <a href="#" class="prev btn-link" data-ajax-action="load_prev_audio_video_posts">
                            <i class="fa fa-long-arrow-left"></i>
                        </a>

                        <span class="divider">/</span>

                        <a href="#" class="next btn-link" data-ajax-action="load_next_audio_video_posts">
                            <i class="fa fa-long-arrow-right"></i>
                        </a>
                    </div>
                </div>
                
                <div class="post--items post--items-4" data-ajax-content="outer">
                    <ul class="nav row" data-ajax-content="inner">
                        <li class="col-md-8">
                            <div class="post--item post--layout-1 post--type-video post--title-large">
                                <div class="post--img">
                                    <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "750px", height:"370px"}} /></a>
                                    <a href="#" class="cat">Wave</a>
                                    <a href="#" class="icon"><i class="fa fa-eye"></i></a>

                                    <div class="post--info">
                                        <ul class="nav meta">
                                            <li><a href="#">Succubus</a></li>
                                            <li><a href="#">Today 03:52 pm</a></li>
                                        </ul>

                                        <div class="title">
                                            <h2 class="h4"><a href="news-details.html" class="btn-link">Standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum</a></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <hr class="divider hidden-md hidden-lg" />

                        </li>

                        <li class="col-md-4">
                            <ul class="nav">
                                <li>
                                    
                                    <div class="post--item post--type-audio post--layout-3">
                                        <div class="post--img">
                                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}} /></a>

                                            <div class="post--info">
                                                <ul class="nav meta">
                                                    <li><a href="#">Maclaan John</a></li>
                                                    <li><a href="#">16 April 2017</a></li>
                                                </ul>

                                                <div class="title">
                                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                                <li>
                                    
                                    <div class="post--item post--type-video post--layout-3">
                                        <div class="post--img">
                                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}} /></a>

                                            <div class="post--info">
                                                <ul class="nav meta">
                                                    <li><a href="#">Maclaan John</a></li>
                                                    <li><a href="#">16 April 2017</a></li>
                                                </ul>

                                                <div class="title">
                                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                                <li>
                                    
                                    <div class="post--item post--type-video post--layout-3">
                                        <div class="post--img">
                                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}} /></a>

                                            <div class="post--info">
                                                <ul class="nav meta">
                                                    <li><a href="#">Maclaan John</a></li>
                                                    <li><a href="#">16 April 2017</a></li>
                                                </ul>

                                                <div class="title">
                                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                                <li>
                                    
                                    <div class="post--item post--type-audio post--layout-3">
                                        <div class="post--img">
                                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}} /></a>

                                            <div class="post--info">
                                                <ul class="nav meta">
                                                    <li><a href="#">Maclaan John</a></li>
                                                    <li><a href="#">16 April 2017</a></li>
                                                </ul>

                                                <div class="title">
                                                    <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted by the readable</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </li>
                            </ul>
                        </li>
                    </ul>

                    
                    <div class="preloader bg--color-0--b" data-preloader="1">
                        <div class="preloader--inner"></div>
                    </div>
                    
                </div>
                
            </div>
            
            <div class="ad--space pd--30-0">
                <a href="#">
                <img src={demo_img2} alt="" style={{width: "970px", height:"90px"}} class="center-block" />
                </a>
            </div>
            
    </>
  )
}
