
import './css/font-awesome.min.css';
import './css/bootstrap.min.css';
import './css/fontawesome-stars-o.min.css';
import './App.css';
import './css/responsive-style.css';
import './css/colors/theme-color-1.css';
import './css/custom.css';

import React, { Component } from 'react'
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import News from './components/News';
import Homepage from './components/Homepage';
import LoadingBar from 'react-top-loading-bar'
import Newslist from './components/Newslist';
import Newddetails from './components/Newddetails';

export default class App extends Component {
  constructor(){
    super();
    this.state = {
      progress:0
    }
    
}
  
  setProgress = (progress)=>{
    this.setState({
      progress:progress
    })
  }

  render() {
    return (
      <div>
        <Router>
        {/* <LoadingBar
          color='#f11946'
          progress={this.state.progress}
        />
          <Navbar /> */}
          
            <Routes>
              {/* <Route exact path="/" element={<News setProgress={this.setProgress} key="generals" category="general" />} />
              <Route exact path="/business" element={<News setProgress={this.setProgress} key="business" category="business" />} />
              <Route exact path="/entertainment" element={<News setProgress={this.setProgress} key="entertainment" category="entertainment" />} />
              <Route exact path="/general" element={<News setProgress={this.setProgress} key="general" category="general" />} />
              <Route exact path="/health" element={<News setProgress={this.setProgress} key="health" category="health" />} />
              <Route exact path="/science" element={<News setProgress={this.setProgress} key="science" category="science"  />} />
              <Route exact path="/sports" element={<News setProgress={this.setProgress} key="sports" category="sports" />} />
              <Route exact path="/technology" element={<News setProgress={this.setProgress} key="technology" category="technology" />} /> */}
              <Route exact path="/" element={<Homepage />} />
              <Route exact path="/news/:slug" element={<Newslist />}   />
              <Route exact path="/news-details/:slug" element={<Newddetails />} />
            </Routes>
        </Router>
      </div>
    )
  }
}
