import React, { useEffect, useState } from "react";
import logo from './../img/logo.png'
import demo_img1 from './../img/home-img/banner-02.jpg'
import { Link } from "react-router-dom";
import axios from 'axios'
import { API_PATH } from "./../constants/index";

export default function Header() {
    const [category, setCategory] = useState({});

    const getCategory = async () => {
        axios({
            url: `${API_PATH}/getCategory`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            // data: payload,
        }).then((res) => {
            // console.log(res.data)
            setCategory(res.data.data)
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        getCategory();
    }, []);

  return (
    <>
        <header className="header--section header--style-1">
            <div className="header--topbar bg--color-2">
                <div className="container">
                    <div className="float--left float--xs-none text-xs-center">
                        <ul className="header--topbar-info nav">
                            <li><i className="fa fm fa-map-marker"></i>New Delhi</li>
                            <li><i className="fa fm fa-mixcloud"></i>21<sup>0</sup> C</li>
                            <li><i className="fa fm fa-calendar"></i>Today (Sunday 19 April 2017)</li>
                        </ul>
                  </div>

                    <div className="float--right float--xs-none text-xs-center">
                        <ul className="header--topbar-action nav">
                            <li><a href="#"><i className="fa fm fa-user-o"></i>Login/Register</a></li>
                        </ul>
                        
                        <ul className="header--topbar-lang nav">
                            <li className="dropdown">
                                <a href="#" className="dropdown-toggle" data-toggle="dropdown"><i className="fa fm fa-language"></i>English<i className="fa flm fa-angle-down"></i></a>

                                <ul className="dropdown-menu">
                                    <li><a href="#">English</a></li>
                                    <li><a href="#">Spanish</a></li>
                                    <li><a href="#">French</a></li>
                                </ul>
                            </li>
                        </ul>
                        
                        <ul className="header--topbar-social nav hidden-sm hidden-xxs">
                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i className="fa fa-rss"></i></a></li>
                            <li><a href="#"><i className="fa fa-youtube-play"></i></a></li>
                        </ul>
                        
                    </div>
                </div>
            </div>
            
            <div className="header--mainbar">
                <div className="container">
                    
                    <div className="header--logo float--left float--sm-none text-sm-center">
                        <h1 className="h1">
                            <Link to="/" className="btn-link">
                                <img src={logo} alt="USNews Logo" />
                                <span className="hidden">News Logo</span>
                            </Link>
                        </h1>
                    </div>
                    
                    <div className="header--ad float--right float--sm-none hidden-xs">
                        <a href="#">
                            <img src={demo_img1} alt="" style={{width: "720px", height:"90px"}}/>
                        </a>
                    </div>
                    
                </div>
            </div>
            
            <div className="header--navbar style--1 navbar bd--color-1 bg--color-1" data-trigger="sticky">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#headerNav" aria-expanded="false" aria-controls="headerNav">
                            <span className="sr-only">Toggle Navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    <div id="headerNav" className="navbar-collapse collapse float--left">
                        <ul className="header--menu-links nav navbar-nav">
                        {
                            category.length > 0 && category.map((val, index) => (
                                <>
                                    <li key={index}><Link to={ "/news/"+val.slug} state={{ from: val.slug }}><a href="#">{ val.name }</a></Link></li>
                                </>
                            ))
                        }
                          
                        {/* <li><a href="news-list.html">Government</a></li>
                        <li><a href="news-list.html">Sports</a></li>
                        <li><a href="news-list.html">Entertainment</a></li>
                        <li><a href="news-list.html">Crime</a></li>
                        <li><a href="news-list.html">Religion</a></li>
                        <li><a href="news-list.html">Economy</a></li>
                        <li><a href="news-list.html">Lifestyle</a></li>
                        <li><a href="news-list.html">Sarcasm</a></li> */}
                      </ul>
                    </div>

                    <form action="#" className="header--search-form float--right" data-form="validate">
                        <input type="search" name="search" placeholder="Search..." className="header--search-control form-control" required />

                        <button type="submit" className="header--search-btn btn"><i className="header--search-icon fa fa-search"></i></button>
                    </form>
                </div>
            </div>
        </header>
    </>
  )
}
