import React, { useEffect, useState } from "react";
import { Link, useLocation  } from "react-router-dom";
import axios from 'axios'
import { API_PATH } from "./../constants/index";

import Footer from './Footer'
import Header from './Header'
import Newsflash from './Common/Newsflash'
import Topfilter from './Common/Topfilter'
import Advertisementfirst from './Advertisement/Advertisementfirst'
import Newsletterform from './Common/Newsletterform'
import Socialconnect from './Common/Socialconnect'
import Featurednews from './Common/Featurednews'
import Advertisementsecond from './Advertisement/Advertisementsecond'
import Testimonial from './Common/Testimonial'

export default function Newslist() {
    
    const location = useLocation()
    const { from } = location.state

    const [newsList, setNewsList] = useState({});

    console.log(from)

    const getNewsList = async () => {
        axios({
            url: `${API_PATH}/getNewsList/${from}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            // data: payload,
        }).then((res) => {
            // console.log(res.data)
            setNewsList(res.data.data)
            // console.log(topNews[0].category)
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        getNewsList();
    }, [from]);

    const capitalizeFirstLowercaseRest = str => {
        return (
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        );
    };

    
  return (
    <>
        <div className="wrapper">
            <Header />
            <Topfilter />
            <Newsflash />
            <div className="main-content--section pbottom--30">
              <div className="container">
              <div class="row">
                    <div class="main--breadcrumb">
                        <div class="container">
                            <ul class="breadcrumb">
                                <li><Link to={ "/"} ><a href="javascript:void(0)" class="btn-link"><i class="fa fm fa-home"></i>Home</a></Link></li>
                                <li class="active"><span><Link to={ "/news/"+from} state={{ from: from }}>{ capitalizeFirstLowercaseRest(from) }</Link></span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="main--content col-md-8 col-sm-7" data-sticky-content="true">
                        <div class="sticky-content-inner">
                            
                            <div class="page--title ptop--30">
                                <h2 class="h2">News List</h2>
                            </div>
                            
                            {
                                newsList?<>
                                    <div class="post--items post--items-5 pd--30-0">
                                    <ul class="nav">
                                        { newsList.length > 0 && newsList.map((val, index) => (
                                            <li key={index}>
                                                <div class="post--item post--title-larger">
                                                    <div class="row">
                                                        <div class="col-md-4 col-sm-12 col-xs-4 col-xxs-12">
                                                            <div class="post--img">
                                                            <Link to={ "/news-details/"+val.slug} state={{ from: val.slug }}><a href="javascript:void(0)" class="thumb"><img src={val.image} alt="" /></a></Link>
                                                                {/* <a href="#" class="cat">Kids</a> */}
                                                            </div>
                                                        </div>

                                                        <div class="col-md-8 col-sm-12 col-xs-8 col-xxs-12">
                                                            <div class="post--info">
                                                                <ul class="nav meta">
                                                                    {/* <li><a href="#">Bushyasta</a></li> */}
                                                                    <li><Link to={ "/news-details/"+val.slug} state={{ from: val.slug }}><a href="javascript:void(0)">{ val.news_date }</a></Link></li>
                                                                </ul>

                                                                <div class="title">
                                                                    <h3 class="h4"><Link to={ "/news-details/"+val.slug} state={{ from: val.slug }}><a href="javascript:void(0)" class="btn-link">{ val.title }</a></Link></h3>
                                                                </div>
                                                            </div>

                                                            <div class="post--content">{ val.short_desc }</div>

                                                            <div class="post--action">
                                                            <Link to={ "/news-details/"+val.slug} state={{ from: val.slug }}><a href="javascript:void(0)">Continue Reading...</a></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </li>
                                        ))
                                        }
                                    </ul>
                                </div>
                                
                                {/* <div class="ad--space">
                                    <a href="#">
                                        <img src="img/ads-img/ad-728x90-02.jpg" alt="" class="center-block" />
                                    </a>
                                </div> */}
                                
                                                        
                                <div class="pagination--wrapper clearfix bdtop--1 bd--color-2 ptop--60 pbottom--30">
                                    <p class="pagination-hint float--left">Page 02 of 03</p>
                                    <ul class="pagination float--right">
                                        <li><a href="#"><i class="fa fa-long-arrow-left"></i></a></li>
                                        <li><a href="#">01</a></li>
                                        <li class="active"><span>02</span></li>
                                        <li><a href="#">03</a></li>
                                        <li>
                                            <i class="fa fa-angle-double-right"></i>
                                            <i class="fa fa-angle-double-right"></i>
                                            <i class="fa fa-angle-double-right"></i>
                                        </li>
                                        <li><a href="#">20</a></li>
                                        <li><a href="#"><i class="fa fa-long-arrow-right"></i></a></li>
                                    </ul>
                                </div>
                                </>:""
                            }
                            
                            
                        </div>
                    </div>
                    
                    <div class="main--sidebar col-md-4 col-sm-5 ptop--30 pbottom--30" data-sticky-content="true">
                        <div class="sticky-content-inner">
                            <Advertisementfirst />
                            <Socialconnect />
                            <Newsletterform />
                            <Featurednews />
                            <Advertisementsecond />
                            <Testimonial />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        <Footer />
    </div>
    </>
  )
}
