import React from 'react'
import demo_img1 from './../../img/home-img/avatar-02.jpg'
import demo_img2 from './../../img/home-img/avatar-01.jpg'

export default function Testimonial() {
  return (
    <div class="widget">
        <div class="widget--title" data-ajax="tab">
            <h2 class="h4">Readers Opinion</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_readers_opinion_widget">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_readers_opinion_widget">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>

        <div class="list--widget list--widget-2" data-ajax-content="outer">
            <div class="post--items post--items-3">
                <ul class="nav" data-ajax-content="inner">
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <span class="thumb"><img src={demo_img2} alt="" style={{width: "70px", height:"70px"}}/></span>

                                <div class="post--info">
                                    <div class="title">
                                        <h3 class="h4">Long established fact that a reader will be distracted</h3>
                                    </div>

                                    <ul class="nav meta">
                                        <li><span>by Ninurta</span></li>
                                        <li><span>16 April 2017</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <span class="thumb"><img src={demo_img1} alt="" style={{width: "70px", height:"70px"}}/></span>

                                <div class="post--info">
                                    <div class="title">
                                        <h3 class="h4">Long established fact that a reader will be distracted</h3>
                                    </div>

                                    <ul class="nav meta">
                                        <li><span>by Ninurta</span></li>
                                        <li><span>16 April 2017</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <span class="thumb"><img src={demo_img2} alt="" style={{width: "70px", height:"70px"}}/></span>

                                <div class="post--info">
                                    <div class="title">
                                        <h3 class="h4">Long established fact that a reader will be distracted</h3>
                                    </div>

                                    <ul class="nav meta">
                                        <li><span>by Ninurta</span></li>
                                        <li><span>16 April 2017</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="preloader bg--color-0--b" data-preloader="1">
                    <div class="preloader--inner"></div>
                </div>
            </div>
        </div>
    </div>
  )
}
