import React, { useEffect, useState } from "react";
import demo_img1 from './../../img/home-img/banner-01.jpg'
import { Link } from "react-router-dom";
import axios from 'axios'
import { API_PATH } from "./../../constants/index";

export default function Firstsection() {

    const [topNews, setTopNews] = useState({});

    const getTopNews = async () => {
        axios({
            url: `${API_PATH}/getTopNews`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            // data: payload,
        }).then((res) => {
            // console.log(res.data)
            setTopNews(res.data.data)
            console.log(topNews[0].category)
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        getTopNews();
    }, []);

  return (
        <div className="main--content">
            <div className="post--items post--items-1 pd--30-0">
                <div className="row gutter--15">
                    {topNews?<>
                        <Link to={ topNews[0] ? "/news-details/"+topNews[0].slug: ""} state={{ from: topNews[0] ? topNews[0].slug:"" }}>
                            <div className="col-md-6">
                                <div className="post--item post--layout-1 post--title-larger">
                                    <div className="post--img">
                                        <a href="javascript:void(0);" className="thumb"><img src={topNews[0] ? topNews[0].image: ""} alt="" style={{width: "562px", height:"389px"}} /></a>
                                        <a href="javascript:void(0)" className="cat">{topNews[0] ? topNews[0].category: ""}</a>
                                        <a href="javascript:void(0)" className="icon"><i className="fa fa-flash"></i></a>

                                        {/* <div className="post--map">
                                            <p className="btn-link"><i className="fa fa-map-o"></i>Location in Google Map</p>

                                            <div className="map--wrapper">
                                                <div data-map-latitude="23.790546" data-map-longitude="90.375583" data-map-zoom="6" data-map-marker="[[23.790546, 90.375583]]"></div>
                                            </div>
                                        </div> */}

                                        <div className="post--info">
                                            <ul className="nav meta">
                                                {/* <li><a href="#">Norma R. Hogan</a></li> */}
                                                <li><a href="javascript:void(0)">{topNews[0] ? topNews[0].news_date: ""}</a></li>
                                            </ul>

                                            <div className="title">
                                                <h2 className="h4"><a href="javascript:void(0)" className="btn-link">{topNews[0] ? topNews[0].short_desc: ""}</a></h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        
                        <div className="col-md-6">
                            <div className="row gutter--15">
                                <Link to={ topNews[1] ? "/news-details/"+topNews[1].slug: ""} state={{ from: topNews[1] ? topNews[1].slug:"" }}>
                                    <div className="col-xs-6 col-xss-12">
                                        <div className="post--item post--layout-1 post--title-large">
                                            <div className="post--img">
                                                <a href="javascript:void(0)" className="thumb"><img src={topNews[1] ? topNews[1].image: ""} alt="" style={{width: "273px", height:"187px"}} /></a>
                                                <a href="javascript:void(0)" className="cat">{topNews[1] ? topNews[1].category: ""}</a>
                                                <a href="javascript:void(0)" className="icon"><i className="fa fa-flash"></i></a>

                                                <div className="post--info">
                                                    <ul className="nav meta">
                                                        {/* <li><a href="#">Corey I. Dean</a></li> */}
                                                        <li><a href="javascript:void(0)">{topNews[1] ? topNews[1].news_date: ""}</a></li>
                                                    </ul>

                                                    <div className="title">
                                                        <h2 className="h4"><a href="javascript:void(0)" className="btn-link">{topNews[1] ? topNews[1].short_desc: ""}</a></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={ topNews[2] ? "/news-details/"+topNews[2].slug: ""} state={{ from: topNews[2] ? topNews[2].slug:"" }}>
                                    <div className="col-xs-6 hidden-xss">
                                        <div className="post--item post--layout-1 post--title-large">
                                            <div className="post--img">
                                                <a href="javascript:void(0)" className="thumb"><img src={topNews[2] ? topNews[2].image: ""}  alt="" style={{width: "273px", height:"187px"}} /></a>
                                                <a href="javascript:void(0)" className="cat">{topNews[2] ? topNews[2].category: ""} </a>
                                                <a href="javascript:void(0)" className="icon"><i className="fa fa-flash"></i></a>

                                                {/* <div className="post--map">
                                                    <p className="btn-link"><i className="fa fa-map-o"></i>Location in Google Map</p>

                                                    <div className="map--wrapper">
                                                        <div data-map-latitude="23.790546" data-map-longitude="90.375583" data-map-zoom="6" data-map-marker="[[23.790546, 90.375583]]"></div>
                                                    </div>
                                                </div> */}

                                                <div className="post--info">
                                                    <ul className="nav meta">
                                                        {/* <li><a href="#">Leraje</a></li> */}
                                                        <li><a href="javascript:void(0)">{topNews[2] ? topNews[2].news_date: ""}</a></li>
                                                    </ul>

                                                    <div className="title">
                                                        <h2 className="h4"><a href="javascript:void(0)" className="btn-link">{topNews[2] ? topNews[2].short_desc: ""}</a></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                <Link to={ topNews[3] ? "/news-details/"+topNews[3].slug: ""} state={{ from: topNews[3] ? topNews[3].slug:"" }}>
                                    <div className="col-sm-12 hidden-sm hidden-xs">
                                        <div className="post--item post--layout-1 post--title-larger">
                                            <div className="post--img">
                                                <a href="javascript:void(0)" className="thumb"><img src={topNews[3] ? topNews[3].image: ""} alt="" style={{width: "562px", height:"186px"}} /></a>
                                                <a href="javascript:void(0)" className="cat">{topNews[3] ? topNews[3].category: ""} </a>
                                                <a href="javascript:void(0)" className="icon"><i className="fa fa-fire"></i></a>

                                                <div className="post--info">
                                                    <ul className="nav meta">
                                                        {/* <li><a href="#">Balam</a></li> */}
                                                        <li><a href="javascript:void(0)">{topNews[3] ? topNews[3].news_date: ""} </a></li>
                                                    </ul>

                                                    <div className="title">
                                                        <h2 className="h4"><a href="javascript:void(0)" className="btn-link">{topNews[3] ? topNews[3].short_desc: ""} </a></h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </>:""}
                </div>
            </div>
        </div>
    )
}
