import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Advertisementsecond() {
  return (
    <div class="widget">
        <div class="widget--title">
            <h2 class="h4">Advertisement</h2>
            <i class="icon fa fa-bullhorn"></i>
        </div>

        <div class="ad--widget">
            <a href="#">
                <img src={demo_img1} alt="" style={{width: "300px", height:"250px"}} />
            </a>
        </div>
    </div>
  )
}
