import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Miscellaneous() {
  return (
    <div class="col-md-12 ptop--30 pbottom--30">
        <div class="post--items-title" data-ajax="tab">
            <h2 class="h4">Miscellaneous</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_food_resturent_posts">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_food_resturent_posts">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>
        
        <div class="post--items" data-ajax-content="outer">
            <ul class="nav row gutter--15" data-ajax-content="inner">
                <li class="col-md-4 col-xs-6 col-xxs-12">
                    <div class="post--item post--layout-1">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "240px", height:"194px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">Yeasterday 03:52 pm</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be distracted by</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-xs-12 hidden shown-xxs">
                    <hr class="divider" />
                </li>
                <li class="col-md-4 col-xs-6 col-xxs-12">
                    <div class="post--item post--layout-1">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "240px", height:"194px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">Yeasterday 03:52 pm</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be distracted by</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="col-md-4 hidden-sm hidden-xs">
                    <div class="post--item post--layout-1">
                        <div class="post--img">
                            <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "240px", height:"194px"}}/></a>

                            <div class="post--info">
                                <ul class="nav meta">
                                    <li><a href="#">Astaroth</a></li>
                                    <li><a href="#">Yeasterday 03:52 pm</a></li>
                                </ul>

                                <div class="title">
                                    <h3 class="h4"><a href="news-details.html" class="btn-link">It is a long established fact that a reader will be distracted by</a></h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="preloader bg--color-0--b" data-preloader="1">
                <div class="preloader--inner"></div>
            </div>
        </div>
    </div>
  )
}
