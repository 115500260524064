import React from 'react'
import Advertisementfirst from '../Advertisement/Advertisementfirst'
import Advertisementsecond from '../Advertisement/Advertisementsecond'
import Advertisementthird from '../Advertisement/Advertisementthird'
import Featurednews from '../Common/Featurednews'
import Newsletterform from '../Common/Newsletterform'
import Socialconnect from '../Common/Socialconnect'
import Economy from '../Newsbox/Economy'
import Entertainment from '../Newsbox/Entertainment'
import Government from '../Newsbox/Government'
import Politics from '../Newsbox/Politics'
import Sport from '../Newsbox/Sport'

export default function Secondsection() {
  return (
    <div class="row">
        <div class="main--content col-md-8 col-sm-7" data-sticky-content="true">
            <div class="sticky-content-inner">
                <div class="row">
                    <Politics />
                    <Government />
                    <Advertisementthird />
                    <Economy />
                    <Sport />
                    <Entertainment />
                </div>
            </div>
        </div>
        
        <div class="main--sidebar col-md-4 col-sm-5 ptop--30 pbottom--30" data-sticky-content="true">
            <div class="sticky-content-inner">
                <Advertisementfirst />
                <Socialconnect />
                <Newsletterform />
                <Featurednews />
                <Advertisementsecond />
            </div>
        </div>
    </div>

    
  )
}
