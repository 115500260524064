import React from 'react'
import demo_img1 from './../../img/home-img/banner-01.jpg'

export default function Crime() {
  return (
    <div class="widget">
        <div class="widget--title" data-ajax="tab">
            <h2 class="h4">Crime</h2>

            <div class="nav">
                <a href="#" class="prev btn-link" data-ajax-action="load_prev_editors_choice_widget">
                    <i class="fa fa-long-arrow-left"></i>
                </a>

                <span class="divider">/</span>

                <a href="#" class="next btn-link" data-ajax-action="load_next_editors_choice_widget">
                    <i class="fa fa-long-arrow-right"></i>
                </a>
            </div>
        </div>

        <div class="list--widget list--widget-1" data-ajax-content="outer">
            <div class="post--items post--items-3">
                <ul class="nav" data-ajax-content="inner">
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                                <div class="post--info">
                                    <ul class="nav meta">
                                        <li><a href="#">Ninurta</a></li>
                                        <li><a href="#">16 April 2017</a></li>
                                    </ul>

                                    <div class="title">
                                        <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                                <div class="post--info">
                                    <ul class="nav meta">
                                        <li><a href="#">Orcus</a></li>
                                        <li><a href="#">16 April 2017</a></li>
                                    </ul>

                                    <div class="title">
                                        <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                                <div class="post--info">
                                    <ul class="nav meta">
                                        <li><a href="#">Rahab</a></li>
                                        <li><a href="#">16 April 2017</a></li>
                                    </ul>

                                    <div class="title">
                                        <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="post--item post--layout-3">
                            <div class="post--img">
                                <a href="news-details.html" class="thumb"><img src={demo_img1} alt="" style={{width: "100px", height:"70px"}}/></a>

                                <div class="post--info">
                                    <ul class="nav meta">
                                        <li><a href="#">Tannin</a></li>
                                        <li><a href="#">16 April 2017</a></li>
                                    </ul>

                                    <div class="title">
                                        <h3 class="h4"><a href="news-details.html" class="btn-link">Long established fact that a reader will be distracted</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="preloader bg--color-0--b" data-preloader="1">
                    <div class="preloader--inner"></div>
                </div>
            </div>
        </div>
    </div>
  )
}
