import React, { useEffect, useState } from "react";
import { Link, useLocation  } from "react-router-dom";
import axios from 'axios'
import { API_PATH } from "./../constants/index";
import avatar_01 from './../img/home-img/avatar-01.jpg'
import avatar_02 from './../img/home-img/avatar-02.jpg'
import Footer from './Footer'
import Header from './Header'
import Newsflash from './Common/Newsflash'
import Topfilter from './Common/Topfilter'
import Advertisementfirst from './Advertisement/Advertisementfirst'
import Newsletterform from './Common/Newsletterform'
import Socialconnect from './Common/Socialconnect'
import Featurednews from './Common/Featurednews'
import Advertisementsecond from './Advertisement/Advertisementsecond'
import Testimonial from './Common/Testimonial'
import Advertisementthird from "./Advertisement/Advertisementthird";


export default function Newddetails() {

    const location = useLocation()
    const { from } = location.state

    const [news, setNewsData] = useState({});

    console.log(from)

    const getNewsDetails = async () => {
        axios({
            url: `${API_PATH}/getNewsDetails/${from}`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            // data: payload,
        }).then((res) => {
            console.log(res.data)
            setNewsData(res.data.data)
            // console.log(topNews[0].category)
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        getNewsDetails();
    }, [from]);

    const capitalizeFirstLowercaseRest = str => {
        return (
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
        );
    };

    const [topNews, setTopNews] = useState({});

    const getTopNews = async () => {
        axios({
            url: `${API_PATH}/getTopNews`,
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
            // data: payload,
        }).then((res) => {
            // console.log(res.data)
            setTopNews(res.data.data)
            console.log(topNews[0].category)
        }).catch((err) => {
            console.log(err)
        });
    };
    useEffect(() => {
        getTopNews();
    }, []);

  return (
    <>
        <div className="wrapper">
            <Header />
            <Topfilter />
            <Newsflash />
            <div className="main-content--section pbottom--30">
              <div className="container">
              <div class="row">
              <div class="main--breadcrumb">
                <div class="container">
                    <ul class="breadcrumb">
                        <li><Link to={ "/"} ><a href="javascript:void(0)" class="btn-link"><i class="fa fm fa-home"></i>Home</a></Link></li>
                        <li><Link to={ news?"/news/"+news.category_slug:""} state={{ from: news?news.category_slug:"" }}><a href="javascript:void(0)" class="btn-link">{news?news.category:""}</a></Link></li>
                        <li class="active"><span>It is a long established fact that a reader will be distracted by</span></li>
                    </ul>
                </div>
            </div>
                {news?<>
                    <div class="main--content col-md-8" data-sticky-content="true">
                        <div class="sticky-content-inner">
                            <div class="post--item post--single post--title-largest pd--30-0">
                                <div class="post--img">
                                    <a href="#" class="thumb"><img src={news.image} alt="" /></a>
                                    {/* <a href="#" class="icon"><i class="fa fa-star-o"></i></a> */}

                                    {/* <div class="post--map">
                                        <p class="btn-link"><i class="fa fa-map-o"></i>Location in Google Map</p>

                                        <div class="map--wrapper">
                                            <div data-map-latitude="23.790546" data-map-longitude="90.375583" data-map-zoom="6" data-map-marker="[[23.790546, 90.375583]]"></div>
                                        </div>
                                    </div> */}
                                </div>

                                <div class="post--cats">
                                    <ul class="nav">
                                        <li><span><i class="fa fa-folder-open-o"></i></span></li>
                                        <li><a href="#">Fashion</a></li>
                                        <li><a href="#">Travel</a></li>
                                        <li><a href="#">Fitness</a></li>
                                        <li><a href="#">Music</a></li>
                                    </ul>
                                </div>

                                <div class="post--info">
                                    <ul class="nav meta">
                                        {/* <li><a href="#">Norma R. Hogan</a></li> */}
                                        <li><a href="#">{news.news_date}</a></li>
                                        <li><span><i class="fa fm fa-eye"></i>45k</span></li>
                                        <li><a href="#"><i class="fa fm fa-comments-o"></i>02</a></li>
                                    </ul>

                                    <div class="title">
                                        <h2 class="h4">{news.title}</h2>
                                    </div>
                                </div>

                                <div class="post--content">
                                    {news.details}

                                    {news.video ? <>
                                        <video width="700" height="400" controls controlsList="nodownload">
                                            <source src={news.video} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </>:""}
                                    
                                </div>
                            </div>
                            
                            <Advertisementthird />

                            
                            
                            <div class="post--tags">
                                <ul class="nav">
                                    <li><span><i class="fa fa-tags"></i></span></li>
                                    <li><a href="#">Fashion</a></li>
                                    <li><a href="#">News</a></li>
                                    <li><a href="#">Image</a></li>
                                    <li><a href="#">Music</a></li>
                                    <li><a href="#">Video</a></li>
                                    <li><a href="#">Audio</a></li>
                                    <li><a href="#">Latest</a></li>
                                    <li><a href="#">Trendy</a></li>
                                    <li><a href="#">Special</a></li>
                                    <li><a href="#">Recipe</a></li>
                                </ul>
                            </div>
                            
                            {/* <div class="post--social pbottom--30">
                                <span class="title"><i class="fa fa-share-alt"></i></span>
                                
                                <div class="social--widget style--4">
                                    <ul class="nav">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
                                        <li><a href="#"><i class="fa fa-rss"></i></a></li>
                                        <li><a href="#"><i class="fa fa-youtube-play"></i></a></li>
                                    </ul>
                                </div>
                                
                            </div> */}
                            
                            {/* <div class="post--author-info clearfix">
                                <div class="img">
                                    <div class="vc--parent">
                                        <div class="vc--child">
                                            <a href="author.html" class="btn-link">
                                                <img src="img/news-single-img/author.jpg" alt="" />
                                                <p class="name">Karla Gleichauf</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div class="info">
                                    <h2 class="h4">About The Author</h2>

                                    <div class="content">
                                        <p>That is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has ab more  normal distribution of letters, as opposed to using content here.</p>
                                    </div>

                                    <ul class="social nav">
                                        <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                                        <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i class="fa fa-rss"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}

                            {/* <div class="post--nav">
                                <ul class="nav row">
                                    <li class="col-xs-6 ptop--30 pbottom--30">
                                        
                                        <div class="post--item">
                                            <div class="post--img">
                                                <a href="#" class="thumb"><img src="img/news-single-img/post-nav-prev.jpg" alt="" /></a>

                                                <div class="post--info">
                                                    <ul class="nav meta">
                                                        <li><a href="#">Astaroth</a></li>
                                                        <li><a href="#">Yeasterday 03:52 pm</a></li>
                                                    </ul>

                                                    <div class="title">
                                                        <h3 class="h4"><a href="#" class="btn-link">On the other hand, we denounce with righteous indignation and dislike demoralized</a></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </li>

                                    <li class="col-xs-6 ptop--30 pbottom--30">
                                        
                                        <div class="post--item">
                                            <div class="post--img">
                                                <a href="#" class="thumb"><img src="img/news-single-img/post-nav-next.jpg" alt="" /></a>

                                                <div class="post--info">
                                                    <ul class="nav meta">
                                                        <li><a href="#">Astaroth</a></li>
                                                        <li><a href="#">Yeasterday 03:52 pm</a></li>
                                                    </ul>

                                                    <div class="title">
                                                        <h3 class="h4"><a href="#" class="btn-link">On the other hand, we denounce with righteous indignation and dislike demoralized</a></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
                            {topNews?<>
                                <div class="post--related ptop--30">
                                
                                    <div class="post--items-title" data-ajax="tab">
                                        <h2 class="h4">You Might Also Like</h2>

                                        <div class="nav">
                                            <a href="#" class="prev btn-link" data-ajax-action="load_prev_related_posts">
                                                <i class="fa fa-long-arrow-left"></i>
                                            </a>

                                            <span class="divider">/</span>

                                            <a href="#" class="next btn-link" data-ajax-action="load_next_related_posts">
                                                <i class="fa fa-long-arrow-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    
                                    <div class="post--items post--items-2" data-ajax-content="outer">
                                        <ul class="nav row" data-ajax-content="inner">
                                            <li class="col-sm-6 pbottom--30">
                                                <div class="post--item post--layout-1">
                                                    <div class="post--img">
                                                    <Link to={ topNews[0] ? "/news-details/"+topNews[0].slug: ""} state={{ from: topNews[0] ? topNews[0].slug:"" }}><a href="javascript:void(0)" class="thumb"><img src={topNews[0] ? topNews[0].image: ""} style={{width: "360px", height:"175px"}} alt="" /></a>
                                                    <a href="javascript:void(0)" class="cat">{topNews[0] ? topNews[0].category: ""}</a>
                                                    <a href="javascript:void(0)" class="icon"><i class="fa fa-flash"></i></a></Link>

                                                        <div class="post--info">
                                                            <ul class="nav meta">
                                                                {/* <li><a href="javascript:void(0)">Astaroth</a></li> */}
                                                                <li><Link to={ topNews[0] ? "/news-details/"+topNews[0].slug: ""} state={{ from: topNews[0] ? topNews[0].slug:"" }}><a href="javascript:void(0)">{topNews[0] ? topNews[0].news_date: ""}</a></Link></li>
                                                            </ul>

                                                            <div class="title">
                                                                <h3 class="h4"><Link to={ topNews[0] ? "/news-details/"+topNews[0].slug: ""} state={{ from: topNews[0] ? topNews[0].slug:"" }}><a href="javascript:void(0)" class="btn-link">{topNews[0] ? topNews[0].title: ""}</a></Link></h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="post--content">
                                                    {topNews[0] ? topNews[0].short_desc: ""}
                                                    </div>

                                                    <div class="post--action">
                                                    <Link to={ topNews[0] ? "/news-details/"+topNews[0].slug: ""} state={{ from: topNews[0] ? topNews[0].slug:"" }}><a href="javascript:void(0)">Continue Reading... </a></Link>
                                                    </div>
                                                </div>
                                                
                                            </li>

                                            <li class="col-sm-6 hidden-xs pbottom--30">
                                                
                                                <div class="post--item post--layout-1">
                                                    <div class="post--img">
                                                    <Link to={ topNews[1] ? "/news-details/"+topNews[1].slug: ""} state={{ from: topNews[1] ? topNews[1].slug:"" }}><a href="javascript:void(0)" class="thumb"><img src={topNews[1] ? topNews[1].image: ""}  alt="" style={{width: "360px", height:"175px"}} /></a>
                                                        <a href="javascript:void(0)" class="cat">{topNews[1] ? topNews[1].category: ""}</a>
                                                        <a href="javascript:void(0)" class="icon"><i class="fa fa-flash"></i></a>
                                                    </Link>
                                                        <div class="post--info">
                                                            <ul class="nav meta">
                                                                {/* <li><a href="javascript:void(0)"></a></li> */}
                                                                <li><Link to={ topNews[1] ? "/news-details/"+topNews[1].slug: ""} state={{ from: topNews[1] ? topNews[1].slug:"" }}><a href="javascript:void(0)">{topNews[1] ? topNews[1].news_date: ""}</a></Link></li>
                                                            </ul>

                                                            <div class="title">
                                                                <h3 class="h4"><Link to={ topNews[1] ? "/news-details/"+topNews[1].slug: ""} state={{ from: topNews[1] ? topNews[1].slug:"" }}><a href="javascript:void(0)" class="btn-link">{topNews[1] ? topNews[1].short_desc: ""}</a></Link></h3>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="post--content">
                                                    {topNews[1] ? topNews[1].news_date: ""}
                                                    </div>

                                                    <div class="post--action">
                                                        <Link to={ topNews[1] ? "/news-details/"+topNews[1].slug: ""} state={{ from: topNews[1] ? topNews[1].slug:"" }}><a href="javascript:void(0)">Continue Reading... </a></Link>
                                                    </div>
                                                </div>
                                                
                                            </li>
                                        </ul>

                                        
                                        <div class="preloader bg--color-0--b" data-preloader="1">
                                            <div class="preloader--inner"></div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                            </>:""}
                            
                            
                            
                            
                            <div class="comment--list pd--30-0">
                                
                                <div class="post--items-title">
                                    <h2 class="h4">03 Comments</h2>

                                    <i class="icon fa fa-comments-o"></i>
                                </div>
                                

                                <ul class="comment--items nav">
                                    <li>
                                        
                                        <div class="comment--item clearfix">
                                            <div class="comment--img float--left">
                                                <img src={avatar_01} alt="" />
                                            </div>

                                            <div class="comment--info">
                                                <div class="comment--header clearfix">
                                                    <p class="name">Karla Gleichauf</p>
                                                    <p class="date">12 May 2017 at 05:28 pm</p>

                                                    <a href="#" class="reply"><i class="fa fa-mail-reply"></i></a>
                                                </div>

                                                <div class="comment--content">
                                                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </li>

                                    <li>
                                        
                                        <div class="comment--item clearfix">
                                            <div class="comment--img float--left">
                                                <img src={avatar_02} alt="" />
                                            </div>

                                            <div class="comment--info">
                                                <div class="comment--header clearfix">
                                                    <p class="name">M Shyamalan</p>
                                                    <p class="date">12 May 2017 at 05:28 pm</p>

                                                    <a href="#" class="reply"><i class="fa fa-mail-reply"></i></a>
                                                </div>

                                                <div class="comment--content">
                                                    <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment</p>
                                                </div>
                                            </div>
                                        </div>
                                        

                                        <ul class="comment--items nav">
                                            <li>
                                                
                                                <div class="comment--item clearfix">
                                                    <div class="comment--img float--left">
                                                        <img src={avatar_01} alt="" />
                                                    </div>

                                                    <div class="comment--info">
                                                        <div class="comment--header clearfix">
                                                            <p class="name">Liz Montano</p>
                                                            <p class="date">12 May 2017 at 05:28 pm</p>

                                                            <a href="#" class="reply"><i class="fa fa-mail-reply"></i></a>
                                                        </div>

                                                        <div class="comment--content">
                                                            <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="comment--form pd--30-0">
                                
                                <div class="post--items-title">
                                    <h2 class="h4">Leave A Comment</h2>

                                    <i class="icon fa fa-pencil-square-o"></i>
                                </div>
                                

                                <div class="comment-respond">
                                    <form action="#" data-form="validate">
                                        <p>Don’t worry ! Your email address will not be published. Required fields are marked (*).</p>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>
                                                    <span>Comment *</span>
                                                    <textarea name="comment" class="form-control" required></textarea>
                                                </label>
                                            </div>

                                            <div class="col-sm-6">
                                                <label>
                                                    <span>Name *</span>
                                                    <input type="text" name="name" class="form-control" required />
                                                </label>

                                                <label>
                                                    <span>Email Address *</span>
                                                    <input type="email" name="email" class="form-control" required />
                                                </label>

                                                <label>
                                                    <span>Website</span>
                                                    <input type="text" name="website" class="form-control" />
                                                </label>
                                            </div>

                                            <div class="col-md-12">
                                                <button type="submit" class="btn btn-primary">Post a Comment</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </>:""}
                    
                    
                    <div class="main--sidebar col-md-4 col-sm-5 ptop--30 pbottom--30" data-sticky-content="true">
                        <div class="sticky-content-inner">
                            <Advertisementfirst />
                            <Socialconnect />
                            <Newsletterform />
                            <Featurednews />
                            <Advertisementsecond />
                            <Testimonial />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        <Footer />
    </div>
    </>
  )
}
