import React from 'react'

export default function Socialconnect() {
  return (
    <div class="widget">
        <div class="widget--title">
            <h2 class="h4">Stay Connected</h2>
            <i class="icon fa fa-share-alt"></i>
        </div>

        <div class="social--widget style--1">
            <ul class="nav">
                <li class="facebook">
                    <a href="#">
                        <span class="icon"><i class="fa fa-facebook-f"></i></span>
                        <span class="count">521</span>
                        <span class="title">Likes</span>
                    </a>
                </li>
                <li class="twitter">
                    <a href="#">
                        <span class="icon"><i class="fa fa-twitter"></i></span>
                        <span class="count">3297</span>
                        <span class="title">Followers</span>
                    </a>
                </li>
                <li class="google-plus">
                    <a href="#">
                        <span class="icon"><i class="fa fa-google-plus"></i></span>
                        <span class="count">596282</span>
                        <span class="title">Followers</span>
                    </a>
                </li>
                <li class="rss">
                    <a href="#">
                        <span class="icon"><i class="fa fa-rss"></i></span>
                        <span class="count">521</span>
                        <span class="title">Subscriber</span>
                    </a>
                </li>
                <li class="vimeo">
                    <a href="#">
                        <span class="icon"><i class="fa fa-vimeo"></i></span>
                        <span class="count">3297</span>
                        <span class="title">Followers</span>
                    </a>
                </li>
                <li class="youtube">
                    <a href="#">
                        <span class="icon"><i class="fa fa-youtube-square"></i></span>
                        <span class="count">596282</span>
                        <span class="title">Subscriber</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
  )
}
