import React from 'react'

export default function Newsletterform() {
  return (
    <div class="widget">
        <div class="widget--title">
            <h2 class="h4">Get Newsletter</h2>
            <i class="icon fa fa-envelope-open-o"></i>
        </div>

        <div class="subscribe--widget">
            <div class="content">
                <p>Subscribe to our newsletter to get  latest news, popular news and exclusive updates.</p>
            </div>

            <form action="https://themelooks.us13.list-manage.com/subscribe/post?u=79f0b132ec25ee223bb41835f&id=f4e0e93d1d" method="post" name="mc-embedded-subscribe-form" target="_blank" data-form="mailchimpAjax">
                <div class="input-group">
                    <input type="email" name="EMAIL" placeholder="E-mail address" class="form-control" autocomplete="off" required />

                    <div class="input-group-btn">
                        <button type="submit" class="btn btn-lg btn-default active"><i class="fa fa-paper-plane-o"></i></button>
                    </div>
                </div>

                <div class="status"></div>
            </form>
        </div>
    </div>
  )
}
